// src/templates/article-detail.js
//import React from "react"
import Prism from 'prismjs';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-cshtml';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-markdown';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-ruby';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-yaml';
import 'prismjs/themes/prism-twilight.css';
import React, { useEffect } from 'react';

import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import Aside from './Aside/aside';
import * as styles from './article.module.css';

const ArticleDetail = ({ data }) => {
  const article = data.nanoCms.article;
  const siteTitle = data.site.siteMetadata?.title || `Title`;

  // prismjsの呼び出し
  useEffect(() => {
    // 全ての <code> タグを取得
    document.querySelectorAll('pre > code:not([class])').forEach((block) => {
      // classが未設定のものに対して、language-pythonを設定
      block.className = 'language-python';
    });
    Prism.highlightAll();
  }, []);
  useEffect(() => {
    const articleItem = {
      '@context': 'https://schema.org',
      '@type': 'Article',
      headline: data.nanoCms.article.title,
      image: data.nanoCms.article.ogpImage,
    };

    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.innerHTML = JSON.stringify(articleItem);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  });

  return (
    <Layout location="ja" title={siteTitle}>
      <main className={styles.articleMain}>
        <div className={styles.cntWrapper}>
          <Breadcrumb
            crumbs={[
              { pathname: '/', crumbLabel: 'TOP' },
              { pathname: '/pages', crumbLabel: 'コラム' },
              {
                pathname: `/articles/${article.url}`,
                crumbLabel: article.title,
              },
            ]}
          />
          <div className={styles.cntWrapperIndex}>
            <div className={styles.mainnav}>
              <article className={styles.post}>
                <div className={styles.articleBar}>
                  <div className={styles.categoryBar}>
                    {article.category || `pages`}
                  </div>
                  <div className={styles.postDate}>{article.data}</div>
                </div>
                <h1>{article.title}</h1>
                <div className={styles.subTitle}>{article.description}</div>
                {article.ogpImage ? (
                  <img
                    src={article.ogpImage}
                    alt="ogp"
                    className={styles.featuredImage}
                  />
                ) : (
                  <StaticImage
                    src="../../../images/nanocms.png"
                    alt="ogp"
                    className={styles.featuredImage}
                  />
                )}
                <div dangerouslySetInnerHTML={{ __html: article.content }} />
                <hr />
              </article>
            </div>
            <Aside />
          </div>
        </div>
      </main>
    </Layout>
  );
};

export const Head = ({ data }) => {
  const article = data.nanoCms.article;
  const siteUrl = data.site.siteMetadata?.siteUrl;
  return (
    <Seo
      title={article.title}
      description={article.description}
      cover={article.ogpImage || `${siteUrl}/nanocms.png`}
    />
  );
};

export default ArticleDetail;

export const query = graphql`
  query ($timestamp: Int!) {
    nanoCms {
      article(timestamp: $timestamp) {
        title
        description
        url
        ogpImage
        category
        content
        updatedAt
        timestamp
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
